import React from 'react';
import {View, Text, ImageBackground, TouchableOpacity, Image, Dimensions} from 'react-native';
import style from './style';
import api from '../config/api_urls'
import Menu from "../menu/menu";
import ReactMarkdown from 'react-markdown'
import {postStadistic} from "../../helpers/petitions";

export default class InfoItinerari extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params;
    }

    goMapa() {
        postStadistic({token: this.state.user_token, tipus_peticio: "Inici itinerari", peticio: "Inici itinerari " + this.state.itinerariActual.Nom})
        this.props.navigation.navigate('Mapa', this.state)
    }

    render(){
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2')['imatge_recurs' + this.state.idioma].url}} style={style.imageBackground}>
                        <View style={{height: "100%", width: "100%", position: "absolute"}}>
                            <View style={{flex: 16, justifyContent: "center", alignItems: "center"}}>
                                <View style={style.titolContainer}>
                                    <Text style={style.titol}>{this.state.itinerariActual["Nom" + this.state.idioma]}</Text>
                                    <Text style={style.subtitol}>{this.state.itinerariActual["descripcio" + this.state.idioma]}</Text>
                                </View>
                            </View>
                            <View style={{flex: 32, justifyContent: "center", alignItems: "center"}}>
                                <View style={style.descriptionContainer}>
                                    <ReactMarkdown style={style.descripcio}>{this.state.itinerariActual["descripcio2Detallat" + this.state.idioma]}</ReactMarkdown>
                                </View>
                            </View>
                            <View style={{flex: 32, justifyContent: "center", alignItems: "center"}}>
                                <Image style={{height: "90%", width: "100%", resizeMode: 'contain'}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itineraris_imatge_2')['imatge_recurs' + this.state.idioma].url}} />
                            </View>
                            <View style={{flex: 10, justifyContent: "center", alignItems: "center"}}>
                                <TouchableOpacity  onPress={() => {
                                    this.goMapa()
                                }} style={{}}>
                                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs == 'text_continuar')["text_recurs" + this.state.idioma]}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{flex: 10}} />
                        </View>
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}
