import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Platform,Dimensions} from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { WebView } from 'react-native-webview';
import style from './style';
import Menu from '../menu/menu';
import api from '../config/api_urls';
import ReactMarkdown from 'react-markdown'

export default class Joc1 extends React.Component{

    constructor(props){
        super(props);
        this.state = props.route.params;
    //    console.log(this.state);
        if (typeof this.state.idioma === 'undefined') {
          this.state['idioma'] = '';
        }
    }

    componentDidMount(){
      this.getIdioma();
    }

    getIdioma = async () => {
      try{
        const value = await AsyncStorage.getItem('idioma');
        if(value !== null){
          this.setState({idioma: value});
        }
      }catch(e){
        console.log(e);
      }
    }

    render(){
        return(   
          <View style={{ flex: 1 }}>
            <View style={{ flex: 15 }}>
              <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2').imatge_recurs.url }} style={style.imageBackground}>
                <View style={{marginTop: this.state.pop_up_margin_top, maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.15 ,}}>
                <View style={[style.caixaOut, {backgroundColor: this.state.projecte.colorPrincipal2}]}>
                <View style={[style.caixa,this.state.projecte.BorderContenidor ? style.caixaBorderContenidor : null , { borderColor: this.state.projecte.ColorBorderContenidor} ]}>
                      <ScrollView> 
                        <View style={{flex:1, justifyContent:'center'}}>
                          <Image style={{height: this.state.pop_up_height * 0.17, resizeMode: 'contain', paddingTop: 2}} source={{ uri: api.api_url + this.state.selectedPage.Imatge.url }} />
                          <Text  style={[style.titol,{color:this.state.projecte.ColorCorporatiuText1}]}>{this.state.selectedPage['Nom'+this.state.idioma]}</Text>
                          <View style={{justifyContent:'center', paddingLeft: 30, paddingRight: 30,color: this.state.projecte.ColorCorporatiuText2,}}>
                            <ReactMarkdown style={style.text}>{this.state.selectedPage['Tipus1Text'+this.state.idioma]}</ReactMarkdown>
                          </View>     
                        </View>
                      </ScrollView>
                    </View>
                  </View>
                </View>
              </ImageBackground>
            </View>
            <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
              <Menu projecte={this.state}></Menu>
            </View>
          </View>
        )
      }   
    }
