import React from 'react';
import { View, Text, ImageBackground, Image, ScrollView,Dimensions } from 'react-native';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import ReactMarkdown from 'react-markdown';
import AsyncStorage from '@react-native-community/async-storage';


export default class Credits extends React.Component {
    state = {};
    constructor(props) {
        super(props);
        this.state = props.route.params;
    }

   render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{ flex: 15 }}>
                    <ImageBackground source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus4').imatge_recurs.url}} style={style.imageBackground}>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.40, justifyContent: "center", alignItems: "center"}}>
                            <Image style={{height: "80%", width: "80%", resizeMode: "contain"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_talp_contacte')['imatge_recurs' + this.state.idioma].url}}/>
                        </View>
                        <ScrollView style={{height: Dimensions.get('screen').height * (13 / 15) * 0.50}}>
                            <View style={{ marginHorizontal: '5%', color: this.state.projecte.ColorCorporatiuText2, fontSize: 13, textAlign: "center", fontFamily: 'OpenSans' }}>
                                <ReactMarkdown style={style.text}>{this.state.projecte['Credits'+this.state.idioma]}</ReactMarkdown>
                            </View>
                        </ScrollView>
                        <View style={{height: Dimensions.get('screen').height * (13 / 15) * 0.10}} />
                    </ImageBackground>
                </View>
                <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
            </View>
        )
    }
}