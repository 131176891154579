import React, { useState, useRef } from "react";
import { View,Dimensions, ImageBackground} from 'react-native';
import Menu from '../menu/menu';
import { PermissionsTunnel } from "iframe-permissions-tunnel";
import api from '../config/api_urls';
import { StatusBar } from "expo-status-bar";
import { PlayvisitEmbed } from "./PlayvisitEmbed";


export default class Joc2 extends React.Component{

    constructor(props){
        super(props);
        this.state = props.route.params;
    }
    appendGameToken(url){
      const gameId = this.getGameid();
      const gameToken = localStorage.getItem("gameToken_"+gameId)
        if (gameToken) {
          console.log("GameToken =>>"+gameToken);
          return url + "?gametoken=" + gameToken;
        } else {
           return url;
        }
    }

    getGameid(){
      let getId = this.state.selectedPage["LinkTipus2"+this.state.idioma].split("/")
      console.log("GameID =>"+getId[getId.length -1]);
      return(getId[getId.length -1])
    }

      getItinerari(){
      let playvisitUrl = this.appendGameToken(this.state.selectedPage["LinkTipus2"+this.state.idioma])
      console.log(playvisitUrl);
        return (
          <View style={{backgroundColor: "#fff"}}>
            <PlayvisitEmbed
              link={playvisitUrl}
              idioma ={this.state.idioma}
              props = {this.props}
              state = {this.state} 
            />
            </View>
        );
      };


    render(){
      return (
        
        <View style={{ flex: 1,backgroundColor: "#fff",height:'99%',width:'99.8%'}}>
                {this.getItinerari()}
                <div style={{height:(Dimensions.get('window').height/100)*10}} >
                  <View style={{ flex: 2, bottom:0,left:2,right:0,position:'absolute',width:'98%'}}>
                    <Menu projecte={this.state}></Menu>
                  </View>
                </div> 
        </View>
       
      );
    }
}

//this.state.selectedPage['Tipus2Url'+this.state.idioma]
