import React, { Component } from "react";
import { StyleSheet, Text, TouchableHighlight, View } from "react-native";
import Modal from "modal-react-native-web";
import style from './style';

export default class Proves extends React.Component{
  state = {
    visible: false
  };

  setModalVisible = visible => {
    this.setState({ visible });
  };

  render() {
    return (
      <View style={{ marginTop: 22 }}>
        
        <Modal
          animationType="slide"
          transparent={false}
          visible={this.state.visible}
          onBackdropPress={() => this.setState({ visible: false })}
        >
          <View style={{ marginTop: 22 }}>
            <View style={style.modalContent}>
              
              <Text>Hello World!</Text>

              <TouchableHighlight
                style={style.button}
                onPress={() => {
                  this.setModalVisible(!this.state.visible);
                }}
              >
                <Text>Hide Modal</Text>
              </TouchableHighlight>
            </View>
          </View>
        </Modal>
        <TouchableHighlight
          style={style.button}
          onPress={() => {
            this.setModalVisible(true);
          }}
        >
          <Text>Show Modal</Text>
        </TouchableHighlight>


      </View>
    );
  }
}
