import { StyleSheet,Dimensions } from 'react-native';

export default StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column"
    },
    imageBackground: {
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center",
      paddingBottom:15,

    },
    imatge: {
      height: 150,
      width: 150,
      justifyContent: "center",
      alignSelf:'center',
      paddingBottom:10
    },
    text: {
      fontFamily: 'OpenSans',
      color: "black",
      fontSize: 23,
      textAlign: "justify",
      justifyContent:'center',
      alignContent:'center',
    },
    caixaOut:{
      backgroundColor:'rgba(116, 81, 110, 0.8)',
      marginHorizontal:'10%', 
      borderRadius:10,
      flex: 1,
    },
    titol: {
      fontFamily: 'Lobster',
      fontSize: 30,
      textAlign: "center",
      marginBottom: 1,
      borderBottomColor:'white',
      borderBottomWidth:1,
      paddingBottom:8,
      width:'90%',
      alignSelf:'center'
    },
    caixa:{
      flex: 1,
      marginHorizontal:10,
      marginVertical:10,
    },
    caixaBorderContenidor:{
      borderWidth: 3,
      borderRadius:10,
    },
});