import React from 'react';
import { View, Text,ImageBackground } from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import { gameId } from '../../Mapa/SampleData';

export default class JocTest extends React.Component{

    constructor(props){
        super(props);
        this.state = props.route.params;
    }

    savePosta = async () => { //=> implementar-ho per no perdre el progres al acabar el joc
      try {
        AsyncStorage.setItem('Posta'+gameId,this.state.posta);
      } catch (e) {
        console.log(e);
      }
    }  
    

    render(){
      return(   
        <View style={{ flex: 1 }}>
          <ImageBackground source={{ uri: api.api_url + this.state.projecte['FonsPantalla4'+this.state.idioma].url }} style={style.imageBackground}>
          <View style={{ flex: 15 }}> 
            <Text>{this.state.gameActive.title}</Text>
            <Text>{this.state.gameActive.description}</Text>
          </View>
            </ImageBackground>
        </View>
      )
    }   
}
