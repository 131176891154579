import React from 'react';
import {View, Text, ImageBackground, Image, TouchableOpacity, ScrollView, Dimensions} from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import {Ionicons} from '@expo/vector-icons';
import AsyncStorage from '@react-native-community/async-storage';
import Modal from "modal-react-native-web";
import {postSatisfaccio} from "../../helpers/petitions";
import {getDistance} from "ol/sphere";

//ordre 4
class Home2 extends React.Component {
    _intervalInici = null;

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;

        this.pages = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.pagines !== 'undefined') ? this.state.projecte.pagines : [];
        this.state["selectedPage"] = {}

        //   console.log(this.state)
    }

    async componentDidMount() {
        let finItinerari = await AsyncStorage.getItem('finalitzacio_itinerari')
        console.log(finItinerari)
        if(finItinerari && finItinerari !== "") {
            this.setState({"finalitzacioItinerari": finItinerari})
        }
        else {
            this._intervalInici = setInterval(async () => { //Start the timer
                let currentPage = await AsyncStorage.getItem('currentPage')
                let finItinerari = await AsyncStorage.getItem('finalitzacio_itinerari')
                if(finItinerari && finItinerari !== "" && currentPage === "Home") {
                    this.setState({"finalitzacioItinerari": finItinerari})
                }
            }, 2000)
        }
    }

    componentWillUnmount() {
        clearInterval(this._intervalInici)
    }

    loadPagina(tipus, props) {
        console.log(tipus)
        this.state["selectedPage"] = props;
        if (tipus === "Tipus1") {
            AsyncStorage.setItem('anarCredits', true);
            AsyncStorage.setItem('currentPage', "Joc1")

            this.props.navigation.navigate('Joc1', this.state)
        } else if (tipus === "Tipus2") {
            AsyncStorage.setItem('currentPage', "Joc2")

            AsyncStorage.setItem('anarCredits', false);
            this.props.navigation.navigate('Joc2', this.state)
        }
        else if (tipus === "Tipus3") {
            AsyncStorage.setItem('currentPage', "Pagina6")

            AsyncStorage.setItem('anarCredits', false);
            this.props.navigation.navigate('Pagina6', this.state)
        }
        else if (tipus === "Tipus4") {
            AsyncStorage.setItem('currentPage', "Itineraris")

            AsyncStorage.setItem('anarCredits', false);
            this.props.navigation.navigate('Itineraris', this.state)
        }
    }

    _renderPopUp() {
        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}>
                <View style={style.modalOutBackground}>
                    <View style={style.caixaPopUp}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <Image style={{resizeMode: "contain", width: "100%", height: "20%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_text').imatge_recurs.url}} />

                            <TouchableOpacity style={{width: "100%", height: "20%"}} onPress={() => {
                                postSatisfaccio({token: this.state.user_token, valoracio: "1", joc: this.state.finalitzacioItinerari})
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={{resizeMode: "contain", width: "100%", height: "90%"}}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_1').imatge_recurs.url}} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{width: "100%", height: "20%"}} onPress={() => {
                                postSatisfaccio({token: this.state.user_token, valoracio: "5", joc: this.state.finalitzacioItinerari})
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={{resizeMode: "contain", width: "100%", height: "90%"}}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_2').imatge_recurs.url}} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{width: "100%", height: "20%"}} onPress={() => {
                                postSatisfaccio({token: this.state.user_token, valoracio: "10", joc: this.state.finalitzacioItinerari})
                                AsyncStorage.setItem('finalitzacio_itinerari', "");
                                this.setState({"finalitzacioItinerari": ""})
                            }}>
                                <Image style={{resizeMode: "contain", width: "100%", height: "90%"}}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'satisfaccio_3').imatge_recurs.url}} />
                            </TouchableOpacity>
                        </ImageBackground>
                    </View>
                </View>
            </Modal>
        )
    }

    render() {
        return (
            <View style={{flex: 15}}>
                <ImageBackground
                    source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == '04_background_principal')['imatge_recurs' + this.state.idioma].url}}
                    style={style.imageBackgroundHoritzontal}>
                    <View style={{flex: 1, width: "100%"}}>
                        <View style={{flex: 4}}>

                        </View>
                        <View style={{flex: 4}}>
                            <TouchableOpacity style={{flex: 1}} onPress={()=>{
                                this.loadPagina(this.state.projecte.pagines[0].Tipus, this.state.projecte.pagines[0]); }}>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 4}}>
                            <TouchableOpacity style={{flex: 1}} onPress={()=>{
                                this.loadPagina("Tipus4"); }}>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 4}}>
                            <TouchableOpacity style={{flex: 1}} onPress={()=>{
                                AsyncStorage.setItem('currentPage', "Joc3")

                                this.state.navigation.navigate('Joc3', this.state); }}>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 4}}>
                            <TouchableOpacity style={{flex: 1}} onPress={()=>{
                                AsyncStorage.setItem('currentPage', "Perfil")

                                this.state.navigation.navigate('Perfil', this.state); }}>
                            </TouchableOpacity>
                        </View>
                    </View>
                </ImageBackground>

                {(this.state.finalitzacioItinerari && this.state.finalitzacioItinerari !== "") ? this._renderPopUp() : ""}

            </View>)
    }
}

export default Home2;

/*

for (const [index, value] of this.pages.entries()) {
          items.push(
            <View style={{flex:1 , backgroundColor: value.ColorPagina, paddingTop:7}}>
            <TouchableOpacity key={index}  onPress={()=>{ this.loadPagina(value.Tipus, value); }}>
              <Image source={{ uri: api.api_url + value.Imatge.url}} style={{height: this.state.pop_up_height * 0.17, resizeMode: 'contain', marginTop: 1}}></Image>
              <Text style={[(this.pages.length-1) === index ? style.textUltim : style.text, {color:this.state.projecte.ColorCorporatiuText1}]}>{value['Nom'+this.state.idioma]} </Text>
            </TouchableOpacity>
            </View>
          )
        }
*/