import React from 'react';
import { View,ImageBackground, Image, TouchableOpacity, Dimensions} from 'react-native';
import style from './style';
import api from '../config/api_urls';
import Menu from '../menu/menu';
import AsyncStorage from '@react-native-community/async-storage';

//ordre 4
 class MenuPosicionament extends React.Component{

    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['navigation'] = this.props.navigation;

        this.pages = (typeof this.state.projecte !== 'undefined' && typeof this.state.projecte.pagines !== 'undefined') ? this.state.projecte.pagines : [];
        this.state["selectedPage"] = {}
    }

    loadPagina(tipus, props) {
      this.state["selectedPage"] = props;
      if(tipus == "Tipus1") {
        AsyncStorage.setItem('anarCredits',true);
        this.props.navigation.navigate('Joc1', this.state)
      }
      else if (tipus == "Tipus2") {
         AsyncStorage.setItem('anarCredits',false);
         //IMPORTANT: Joc per anar a playvisit
        // this.props.navigation.navigate('Joc2', this.state)
        // mapa interactiu nouº
        this.props.navigation.navigate('Mapa', this.state)
      }
    }
    changeScreenOrientation = async () => {
        await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
      }

    getImagesWithPosition(){
        return( this.pages.map((value,index) => {
            if(value.Tipus == "Tipus2"){
                return (
                    <View style={{backgroundColor:'red'}} >             
                        <TouchableOpacity key={index}  onPress={()=>{ this.loadPagina(value.Tipus, value); }} 
                        style={[{ alignContent:'center',  height: this.state.pop_up_height * 0.12, width: this.state.width/100 * (value.MidaImatgeMenu ? value.MidaImatgeMenu : 30) ,},
                                value.PosicioEsquerra ? 
                                    {right: ((this.state.width/2 ) + (this.state.width /100 * (value.PosicioHoritzontal ? value.PosicioHoritzontal : 0)))} 
                                    :
                                    {left: ((this.state.width/2 ) + (this.state.width /100 * (value.PosicioHoritzontal ? value.PosicioHoritzontal : 0)))}
                                ,
                                {top:( this.state.height/100 * (value.PosicioVertical ? value.PosicioVertical : 0)),position:'absolute'}]}>
                            <Image source={{ uri: api.api_url + value["Imatge"+this.state.idioma].url}} style={{height: this.state.pop_up_height * 0.2 , resizeMode:'contain'}}/>
                        </TouchableOpacity>
                    </View>
                )
            }
        }))
    } 

    render() {
        return(
          <View style={{ flex: 1, }}>
            <View style={{ flex: 15 }}>
                <ImageBackground source={{ uri: api.api_url + this.state.projecte['FonsPantalla3'+this.state.idioma].url }} style={style.imageBackgroundHoritzontal}>             
                    <View style={{alignContent:'center'}}>
                        {this.getImagesWithPosition()}
                    </View>    
                </ImageBackground>
            </View>
            <View style={{ flex: 2, bottom:0,left:0,right:0, position:'fixed' }}>
                <Menu projecte={this.state}></Menu>
            </View>
        </View>
        )
    }
}
export default MenuPosicionament;


// const items = []
// if(this.pages.length > 0) {
//   for (const [index, value] of this.pages.entries()) {
//     if(value.Tipus != "Tipus3"){
//     items.push(
//       <View style={{flex:1}}>
//         <TouchableOpacity key={index}  onPress={()=>{ this.loadPagina(value.Tipus, value); }}>
//           <View style={{width: ((Dimensions.get('screen').width /100 * 50) + (Dimensions.get('screen').width /100 * 25)), height:( Dimensions.get('screen').height/100 * 20),position:'absolute',}}>
//               <Image source={{ uri: api.api_url + value.Imatge.url}} style={{height: this.state.pop_up_height * 0.12, resizeMode: 'contain', marginTop: 1}}></Image>
//           </View>
//         </TouchableOpacity>
//       </View>
//     )
//   }
// }
// }