import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity,Dimensions,TextInput} from 'react-native';
import style from './style';
import api from '../config/api_urls';
import AsyncStorage from '@react-native-community/async-storage';
import {postStadistic} from '../../helpers/petitions'
import { Video, AVPlaybackStatus } from 'expo-av';



//ordre 1
export default class VideoPrincipal extends React.Component{
    state = {};
    constructor(props) {
        super(props);
        this.state = props.route.params;
        this.state['gameInfo'] = []
        this.state['showVideo'] = false
        this.visualitzacioVideo = false
    }

    componentDidMount(){
        this.getNom();
        AsyncStorage.setItem('anarCredits',true);
    }

    getNom = async () => {
        try{
            const value = await AsyncStorage.getItem('nom');
            if(value !== null){
                this.setState({nom: value});
            }
        }catch(e){
            console.log(e);
        }
    }

    pauseVideo = () => {
        if(this.video) {
            this.video.pause();
        }
    }

    removeVideo = () => {
        if(this.video) {
            this.video.pause();
            this.video.removeAttribute('src'); // empty source
            this.videoSource.removeAttribute('src');
            this.video.load();
        }
    }

    playVideo = () => {
        this.visualitzacioVideo = true
        this.setState({ showVideo: true });
        postStadistic({token: this.state.user_token, tipus_peticio: "Inici visualització video", peticio: "Inici visualització video"})


    }

    explanation = () => {
        this.setState({ showVideo: false });
    }

    changeScreen() {
        if(this.visualitzacioVideo)
            postStadistic({token: this.state.user_token, tipus_peticio: "Fi visualització video", peticio: "Fi de la visualització video"})
        setTimeout(()=>{
            this.removeVideo();
            if(this.state.nom) {
                console.log("entra");
                //   this.props.navigation.navigate('Prova', this.state);
                //todo: canviar per home
                this.props.navigation.navigate('Login', this.state);
            }else{
                this.props.navigation.navigate('Login', this.state);
            }

        },100)
    }

    _renderPantalla() {
        if(!this.state.showVideo) {
            return(
                <View style={{flex: 1}}>
                    <ImageBackground
                        source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == '02_background_video')['imatge_recurs' + this.state.idioma].url}}
                        style={style.imageBackground}>

                        <View style={{flex: 1, width: "100%"}}>
                            <View style={{flex: 24}}>

                            </View>
                            <View style={{flex: 30}}>
                                <TouchableOpacity style={{flex: 1}} onPress={()=>{ this.playVideo(); }}>
                                </TouchableOpacity>
                            </View>
                            <View style={{flex: 36}}>
                            </View>
                            <View style={{flex: 10, justifyContent: "center" }}>
                                <TouchableOpacity onPress={()=>{ this.changeScreen() }}>
                                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs == 'comencar')["text_recurs" + this.state.idioma]}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </ImageBackground>
                </View>
            )
        }
        else {
            return null;
        }
    }

    _renderVideo() {
        if (this.state.showVideo) {
            return (
                <View id="videoContainer" style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: 'black',
                    alignSelf: 'center',
                    justifyContent: 'center'
                }}>
                    <video id="video" onPause={ e => this.explanation() } ref={ref => {
                        this.video = ref
                    }} style={{
                        height: '100%',
                        width: '100vw',
                        resizeMode: 'contain',
                        objectFit: 'contain',
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        zIndex: -1
                    }}
                           autoPlay>
                        <source id="videoSource" ref={ref => {
                            this.videoSource = ref
                        }} src={require("../../img/Geoparc" + this.state.idioma + ".mp4")} type="video/mp4"/>
                        {/* <source src={{uri:this.state.premiTrucada.LinkVideo}} type="video/mp4"  /> */}

                    </video>
                    <TouchableOpacity style={style.crossBox} onPress={() => {
                        this.explanation()
                    }}>
                        <Image style={style.crossImage}
                               source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                    </TouchableOpacity>
                </View>
            )
        }
        else {
            return null;
        }
    }

    render(){
        return(
            <View style={style.container} visible={!this.state.visible} >
                {this._renderVideo()}
                {this._renderPantalla()}
            </View>
        )
    }
}