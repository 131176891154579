import React from 'react';
import {View, Dimensions, Text, TouchableOpacity, Image} from 'react-native';
import style from './style';
import Menu from "../../menu/menu";
import { WebView } from 'react-native-webview';
import api from "../../config/api_urls";


export default class Mineral extends React.Component{

    constructor(props) {
        super(props);
        this.state = props.route.params;
        console.log("mineral page")
        console.log(this.state);
    }

    goBack() {
        this.props.navigation.navigate('Perfil', this.state)
    }

    render(){
        return(
            <View style={{flex: 1}}>
                <iframe src={this.state.currentMineralLink} style={{flex: 1 , width:'100%'}} />

                <TouchableOpacity style={style.crossBox} onPress={() => {
                    this.goBack()
                }}>
                    <Image style={style.crossImage}
                           source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                </TouchableOpacity>
            </View>
        )
    }
}