import * as React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity, ScrollView,Dimensions} from 'react-native';
import style from './style';
import api from '../config/api_urls';


export default class VideoPantallaComplerta extends React.Component{

 constructor(props) {
    super(props);
    this.state = props.route.params;
  }

  componentDidMount(){
    console.log('====================================');
    console.log("TRUCADA");
    console.log(this.state.premiTrucada);
    console.log('====================================');
    if(!this.state.premiTrucada.esUnVideo){
    document.getElementById('audioTrucada').play()
    }
  }
//   this.state.premiTrucada.esUnVideo
    render(){
    
        return(

            <View style={{flex:1}}>
                { this.state.premiTrucada.esUnVideo ?(
                    <View style={{ height:'100%', width:'100%',backgroundColor:'black',alignSelf:'center',justifyContent:'center'}}>
                        <div id="container">
                            <div id="audioTrucada"></div>
                            <video id="video" autoPlay
                            style={{ height:'100%', width:'100vw', resizeMode: 'cover',objectFit:'cover',position:'fixed',left:0,top:0,zIndex:-1 }}>
                                {/*this.state.idioma != '_FR' ? ( <source id="video" src={require('../../img/videoCatcar.mp4')} type="video/mp4"  />): ( <source id="video" src={require('../../img/videoCatcar_FR.mp4')} type="video/mp4"  /> )*/}
                                {/* <source src={{uri:this.state.premiTrucada.LinkVideo}} type="video/mp4"  /> */}
                            </video>
                        </div>
                    </View>
            )
            :
            (
                <View style={{flex:1}}>
                    {console.log(this.state.premiTrucada)}
                    <ImageBackground source={{uri: api.api_url+this.state.premiTrucada.imatge_trucada.url}} style={{ flex: 1, resizeMode: "cover", justifyContent: "center" }} >
                    </ImageBackground>
                    {/* <audio id="audioTrucada" src={require('../../img/olerdola.mp3')}  preload></audio> */}
                    <audio id="audioTrucada" src={require('../../img/AudioTrucada.mp3')}  preload></audio>
                </View>
            )
            }
                <View style={{  flex:0.05, bottom:25,left:0,right:0, position:'fixed', }}>{/*era 70 */}
                    <View style={{flexDirection:'row', width:'100%'}}>
                        <Image source={require('../../img/cameraClio.png')} style={{ height: this.state.pop_up_height * 0.18, width: '33%',resizeMode: 'contain', alignSelf:'center' }} />
                        <TouchableOpacity style={{width:'33%'}} onPress={()=>{ 
                            this.state.premiTrucada.esUnVideo ?   
                                document.getElementById('container').remove('video') :
                                document.getElementById('audioTrucada').remove('audioTrucada'); this.props.navigation.navigate('Home', this.state);}}>
                                <Image source={require('../../img/closeCallClio.png')} style={{ height: this.state.pop_up_height * 0.18, width: '100%',resizeMode: 'contain', alignSelf:'flex-start' }} />
                        </TouchableOpacity>
                        <Image source={require('../../img/microClio.png')} style={{ height: this.state.pop_up_height * 0.18, width: '33%',resizeMode: 'contain', alignSelf:'flex-end' }} />
                    </View>
                </View>
            </View>
            )
        }
    }

//style={{ height:(Dimensions.get('window').height/100)*90, width:'100%'}}


// <audio id="audioTrucada" controls  autoPlay preload style={{visibility:'hidden', width:1,height:1}}>
//                         {/* <source src={require('../../img/AudioTrucada.mp3')} type="audio/ogg"/> */}
//                         <source src={require('../../img/olerdola.mp3')} type="audio/ogg"/>
//                     </audio>


// <TouchableOpacity style={{width:'33%'}} onPress={()=>{ 
//     this.state.premiTrucada.esUnVideo ?   
//         document.getElementById('container').remove('video') :
//         document.getElementById('audioTrucada').remove('audioTrucada'); this.props.navigation.navigate('Home', this.state);}}>
//         <Image source={require('../../img/closeCall.png')} style={{ height: this.state.pop_up_height * 0.2, width: '100%',resizeMode: 'contain', alignSelf:'flex-start' }} />
// </TouchableOpacity>