export const data = [
    {
        id: 1,
        title: "Artés, castell i muralla",
        description:
            "Artés, castell i muralla descripció",
        order: 7,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.95449, 41.79400],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        gameType: "checkIn",
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntActual.png",
            iconSize: 0.1
        },
    },
    {
        id: 2,
        title: "ARTÉS:",
        subtitol: "Per dins dels paleocanals",
        description:
            "Els paleocanals son les lleres fossilitzades d'antics rius i rieres que passaven per aquí fa milions d'anys. Si pareu atenció hi trobareu les traces i entendreu millor aquest entorn",
        order: 6,
        picture: "https://ferranmaso.com/mapsGeo/Talpo.png",
        position: [1.95360, 41.79388],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        gameType: "quizz",
        gameConfig: {
            question: "Quant va existir un mar al cor geogràfic de Catalunya?",
            answers: [
                {id: 1, variant: "click", answer: "Fa 30 anys", valid: false},
                {id: 2, variant: "click", answer: "Fa 36 milions d'anys", valid: true},
                {id: 3, variant: "click", answer: "Fa 5 milions d'anys", valid: false},
                {id: 4, variant: "click", answer: "Fa 10.000 anys", valid: false}
            ]
        },
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntActual.png",
            iconSize: 0.1
        },
    },
    {
        id: 3,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 3,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.95273, 41.79339],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 4,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 4,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.95151, 41.79303],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 5,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 5,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.94940, 41.79236],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 6,
        title: "ARTÉS:",
        subtitol: "Per dins dels paleocanals",
        description:
            "Els paleocanals son les lleres fossilitzades d'antics rius i rieres que passaven per aquí fa milions d'anys. Si pareu atenció hi trobareu les traces i entendreu millor aquest enrtorn",
        order: 6,
        picture:
            "https://ferranmaso.com/mapsGeo/Talpo.png",
        position: [1.94897, 41.79304],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 7,
        title: "Artés, castell i muralla",
        description:
            "Artés, castell i muralla descripció",
        order: 7,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.94636, 41.79173],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 8,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 8,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.94526, 41.79097],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 9,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 9,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.94514, 41.79206],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 10,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 9,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.94430, 41.78915],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 11,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 9,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.94259, 41.78882],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 12,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 9,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.95084, 41.79949],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 13,
        title: "En met Demo 2",
        description:
            "Hola firgola aixo es la DEMO 2",
        order: 9,
        picture:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQHRblI0BcMdMg/company-background_10000/0/1552905854220?e=2159024400&v=beta&t=l1ZJjkecqUDP1QjmmdY8TvCJHY5e2YM41UuTWCEKa7c",
        position: [1.95187, 41.79724],
        Link: "https://en.wikipedia.org/wiki/Louvre",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntRestant.png",
            iconSize: 0.1
        },
    },
    {
        id: 14,
        title: "En met Demo 3",
        description:
            'Hola firgola aixo es la DEMO 3',
        order: 10,
        picture:
            "https://t1.gstatic.com/images?q=tbn:ANd9GcTP7Kev8E9D4tRQx70zbwJejhXbrWDSHe4YPnZRm2_iF3yXnB-J8_f6BbJov2HUz1IWVM2tpRLR2QEqkfyEkIsX1Q",
        position: [1.95097, 41.79738],
        Link: "https://en.wikipedia.org/wiki/Notre-Dame_de_Paris",
        points: 10,
        visible: true,
        myIcon: {
            url: "https://ferranmaso.com/mapsGeo/PuntFinal.png",
            iconSize: 0.1
        },
    },
];
export const gameId = 1;
export const mapaUrl = {
    /*url: 'https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=Fc6S9cypg0ky70A06AUH',*/
    /*url: 'https://tilemaps.icgc.cat/mapfactory/wmts/geologia/MON3857NW/{z}/{x}/{y}.png',*/
    url: 'https://geoserveis.icgc.cat/icc_mapesmultibase/noutm/wmts/orto/GRID3857/{z}/{x}/{y}.png',
    urlNoms: 'https://atilemaps.icgc.cat/mapfactory/wmts/toponimia/CAT3857/{z}/{x}/{y}.png',
    urlCamins: 'https://ctilemaps.icgc.cat/mapfactory/wmts/hibrida_total/CAT3857/{z}/{x}/{y}.png',
    maxZoom: 20
}
export const distanceEvent = 50; //=> DISTANCIA LA QUAL ACTIVARA L'EVENT (es pot fer per cada posta)
export const distanceVisible = 100; //=> DISTANCIA LA QUAL Mostrara el punt (es pot fer per cada posta)
