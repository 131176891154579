import React, { Component } from "react";
import {Text,StyleSheet,View,ImageBackground,Dimensions,Image,TouchableOpacity,Platform,TouchableHighlight,TouchableWithoutFeedback } from "react-native";
import Modal from "modal-react-native-web";
import style from './style';

import api from '../config/api_urls';
import { Video, AVPlaybackStatus } from 'expo-av';
import AsyncStorage from "@react-native-community/async-storage";
import { Button } from "react-native-web";

export default class Telefon extends React.Component{

 constructor(props) {
    super(props);
    this.state = props.route.params;
    this.state['visible']= true;
  }

valuetext(value) {
  return (value);
}
componentDidMount(){
  // document.getElementById('audio').play()
  AsyncStorage.removeItem('lastPoiTrucada') 
}

renderPermissionPage()
{
  if(true)//Platform.OS == 'ios'
  {

    return(
        <Modal
          animationType="fade"
          transparent={true}
          ariaHideApp={false}
          onBackdropPress={() => { document.getElementById('audio').play(); this.setState({ visible: false }); }}
          visible={this.state.visible}
          >
          <View style={{backgroundColor:'rgba(0,0,0, 0.8)',width:'100%',height:'100%'}}>  
            <View style={{ marginTop: 12 }}>
            <TouchableWithoutFeedback onPress={() => {document.getElementById('audio').play(); this.setState({ visible: false });}}>
            {/* onPress={() => {document.getElementById('audio').play(); this.setState({ visible: false });}} */}
            <View style={{maxHeight: this.state.pop_up_height,paddingBottom: Dimensions.get('screen').width * 0.1 , marginHorizontal:'5%'}}>
              <View style={style.caixaOut , {backgroundColor: this.state.projecte.colorPrincipal2,borderRadius:10}}>
                <View style={[style.caixa]}>
                    <Image style={{width:25,height:25,marginLeft:-1}} source={require('../../img/message.png')} />
                    <Text style={{marginTop:-10,marginLeft:50,fontWeight:'bold'}}>{this.state.projecte.NomNotificacioTrucada}</Text> 
                    <View style={{flexDirection:'row'}}>

                      <Text style={{marginLeft:50,marginBottom:5}}>{this.state.projecte['TextTrucada'+this.state.idioma]}</Text>
                      <TouchableOpacity style={{marginLeft:10,backgroundColor:'purple'}}></TouchableOpacity>
                      <TouchableOpacity style={{marginLeft:70}} onPress={() => {document.getElementById('audio').play(); this.setState({ visible: false });}}>
                        <Image style={{width:60,height:60,marginLeft:"80%", marginTop:-30}} source={{ uri: api.api_url + this.state.projecte.ImatgeNotificacioTrucada.url }} />
                      </TouchableOpacity>
                    </View>
                </View>
              </View>
            </View> 
          </TouchableWithoutFeedback>
          </View>
        </View>
        </Modal>
    )
  }else
  {

  }
}

render() {

    return (
      
      <View style={{flex:1}}>
        {this.renderPermissionPage()}
         <ImageBackground source={ this.state.projecte.ImatgeTrucadaInicial ?{ uri: api.api_url + this.state.projecte.ImatgeTrucadaInicial.url } : null} style={{ flex: 1, resizeMode: "cover", justifyContent: "center" }} >
        <View style={{flex:0.7}}>
        </View>
        <View style={{flex:0.1 , flexDirection:'row', alignSelf:'center' }}>
            <TouchableOpacity onPress={() =>{document.getElementById('audio').remove('audio'), this.props.navigation.navigate('Home', this.state);}}  >
              <Image source={require('../../img/closeCallClio.png')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center', right:'70%' }} />            
              {/* <Image source={require('../../img/closeCall.png')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center', right:'70%' }} />             */}
            </TouchableOpacity>
            <TouchableOpacity onPress={() =>{document.getElementById('audio').remove('audio'), this.props.navigation.navigate('Video', this.state);}}  >
                  <Image source={require('../../img/mobileClio.png')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center',left:'70%' }} /> 
                  {/* <Image source={require('../../img/mobile.gif')} style={{ height: this.state.pop_up_height * 0.2, width: this.state.pop_up_height * 0.2,resizeMode: 'contain', alignSelf:'center',left:'70%' }} />             */}
            </TouchableOpacity>
        </View>
        <audio id="audio" src={require('../../img/call.mp3')} loop preload></audio>
         </ImageBackground>

      </View>
    )
  }
}