import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
    text: {
        fontSize: 15,
        textAlign: "justify",
        fontFamily: 'OpenSans',
    },
    titolContainer: {
        backgroundColor: "#a88f40",
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    descriptionContainer: {
        width: "80%",
        textAlign: "center",
        alignSelf: 'center',
        fontFamily: 'OpenSans',
        fontSize: "1.9vh",
        color: "#3d342a"
    },
    titol: {
        fontSize: 25,
        paddingTop: 4,
        textAlign: "center",
        fontWeight: "bold",
        width: '100%',
        alignSelf: 'center',
        color: "white",
        lineHeight: 25
    },
    subtitol: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "500",
        width: '100%',
        alignSelf: 'center',
        color: "white",
        paddingBottom: 4,
    },
    descripcio: {
        color: '#715f4d',
        fontSize: 18,
        textAlign: "justify",
        lineHeight: 20,
        fontFamily: 'OpenSans',
    },
    paragraph: {
        color: '#715f4d',
        fontSize: 16,
        textAlign: "justify",
        fontWeight: 500,
        lineHeight: 20,
        fontFamily: 'OpenSans'
    },
    questionBackground: {
        width: "100%",
        height: "100%",
        justifyContent: "center"
    },
    question: {
        color: '#715f4d',
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center"
    },
    TouchableOpacity: {
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    containerAnswer: {
        backgroundColor: '#a88f40',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: "100%",
        width: "100%",
    },
    containerAnswerBack: {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        width: "100%",
    },
    answer: {
        color: "white",
        fontWeight: "bold",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20
    },
    answerBack: {
        color: "#a88f40",
        fontWeight: "bold",
        fontSize: 15
    },
    talpSolucio: {
        height: 70,
        width: 70
    },
    questionImage: {
        height: "90%",
        width: "90%"
    },
    containerContinue: {
        marginTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: '#a88f40',
        justifyContent: 'center',
        alignItems: 'center',

    },
    continue: {
        fontFamily: 'OpenSans',
        fontSize: 17,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 25,
        paddingRight: 25,
        paddingHorizontal: 10,
        alignSelf: 'center',
        backgroundColor: '#a88f40',
        color: 'white',
        fontWeight: 'bold'
    },
    safeAreaAnswer: {
        width: "70%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        height: 150,
        width: 150,
        margin: 4,
        justifyContent: 'center',
        alignItems: 'center',
    }
});