import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
    },

    imageBackgroundHoritzontal: {
        height: "100%",
        resizeMode: "cover",
        justifyContent: "center",
    },
    imageBackgroundVertical: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 10,
    },
    imatge: {
        height: null,
        width: null,
        resizeMode: "center",
        justifyContent: "center",
        flex: 0.7,
    },
    text: {
        fontFamily: 'Lobster',
        color: "white",
        fontSize: 18,
        textAlign: "center",
        marginBottom: 5,
        //borderBottomColor:'white',
        //borderBottomWidth:1,
        paddingBottom: 5,
        width: '90%',
        alignSelf: 'center'
    },
    textUltim: {
        fontFamily: 'Lobster',
        color: "white",
        fontSize: 18,
        textAlign: "center",
        marginBottom: 5,
        width: '90%',
        alignSelf: 'center'
    },
    titol: {
        color: "black",
        fontSize: 42,
        textAlign: "center",
        marginTop: 15,
    },
    caixaOut: {
        marginHorizontal: '10%',
    },
    caixa: {
        maxHeight: "100%",
        height: "100%",
        borderColor: '#edefe9',
    },
    CaixetiLlistaVertical: {
        flex: 1,
        paddingTop: 7,
        marginHorizontal: '5%',

    },
    /// LLista Horitzontal ///
    CaixetiLlistaHoritzontal: {
        flex: 1,
        paddingHorizontal: 5,
        marginBottom: 10,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 5
    },
    itinerariContainer: {
        paddingTop: 5,
        paddingBottom: 5,
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
        marginTop: 7,
        marginBottom: 7
    },
    itinerariText: {
        fontSize: 24,
        textAlign: "center",
        fontWeight: "bold",
        width: '100%',
        alignSelf: 'center',
    },
    imatgeRecurs: {
        height: "80%",
        width: "80%",
        alignSelf: 'center'
    },
    caixaPopUp: {
        display: "flex",
        height: "60%",
        width: "70%",
        borderColor: "#a88f40",
        borderStyle: "solid",
        borderWidth: 6
    },
    crossBox: {
        position: 'absolute',
        right: 6,
        top: 6
    },
    cross: {
        color: "white",
        fontSize: 20
    },
    crossImage: {
        height: 30,
        width: 30
    },
    modalOutBackground: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
});
