import {StyleSheet} from 'react-native';

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
    },
    crossImage: {
        width: 25,
        height: 25,
        position: "absolute",
        top: -9,
        right: -9,
        zIndex: 5
    },
    imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom: 15,
        alignItems: 'center',
    },
    text: {
        fontSize: 15,
        textAlign: "justify",
        fontFamily: 'OpenSans',
    },
    titolContainer: {
        backgroundColor: "#a88f40",
        paddingTop: 15,
        paddingBottom: 15,
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    descriptionContainer: {
        width: "70%",
        textAlign: "center",
        alignSelf: 'center',
    },
    titol: {
        fontSize: 30,
        textAlign: "center",
        fontWeight: "bold",
        width: '100%',
        alignSelf: 'center',
        color: "white"
    },
    subtitol: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "500",
        width: '100%',
        alignSelf: 'center',
        color: "white"
    },
    descripcio: {
        marginTop: 14,
        color: '#715f4d',
        fontSize: 20,
        textAlign: "justify",
        lineHeight: 26
    },
    questionBackground: {
        width: "100%",
        height: "100%",
        justifyContent: "center"
    },
    question: {
        color: '#715f4d',
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "center"
    },
    TouchableOpacity: {
        height: "100%",
        width: "100%",
        position: "absolute"
    },
    containerAnswer: {
        backgroundColor: '#a88f40',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: "100%",
        width: "100%",
    },
    containerAnswerBack: {
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        width: "100%",
    },
    answer: {
        color: "white",
        fontWeight: "bold",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 20
    },
    answerBack: {
        color: "#a88f40",
        fontWeight: "bold",
        fontSize: 15
    },
    talpSolucio: {
        height: 70,
        width: 70
    },
    questionImage: {
        height: "90%",
        width: "90%"
    },
    containerContinue: {
        paddingLeft: 15,
        paddingRight: 15,
        height: 50,
        backgroundColor: '#a88f40',
        justifyContent: 'center',
        alignItems: 'center',

    },
    continue: {
        color: "white",
        fontWeight: "bold",
        fontSize: 20
    },
    safeAreaAnswer: {
        width: "70%",
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        height: 150,
        width: 150,
        margin: 4,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container2: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'space-evenly',
        alignItems: "center",

    },
    centeredContent: {
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 3
    },
    dropContainer: {
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        paddingTop: 20,
        paddingBottom: 20,
        alignItems: "center"
    },
    receivingZone: {
        height: 61.86,
        width: 85,
        justifyContent: "center"
    },
    receiving: {
    },
    incomingPayload: {
        marginTop: 10,
        fontSize: 24,
    },
    received: {
        marginTop: 10,
        fontSize: 18,
    },
    palette: {
        flexDirection: 'row',
        flexWrap: "wrap",
        flexBasis: "content",
        justifyContent: 'center',
        alignItems: "center",
        width: "100%",
        paddingLeft: "5%",
        paddingRight: "5%"
    },
    draggableBox: {
        width: 86,
        height: 64,
        borderRadius: 10,

    },
    color1: {
        backgroundColor: '#9e2d32',
    },
    color2: {
        backgroundColor: '#00688e',
    },
    color3: {
        backgroundColor: '#a88f40',
    },
    dragging: {
        opacity: 0.2,
    },
    hoverDragging: {
        borderColor: 'white',
        borderWidth: 2,
    },
    stagedCount: {
        fontSize: 18,
    },

});