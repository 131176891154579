import React from 'react';
import { View, Text, ImageBackground, Image, TouchableOpacity,Dimensions,TextInput} from 'react-native';
import style from './style';
import api from '../config/api_urls';
import AsyncStorage from '@react-native-community/async-storage';


//ordre 1
export default class Login extends React.Component{
    state = {};
    constructor(props) {
      super(props);
      this.state = props.route.params;
      this.state['gameInfo']=[]
    }

    componentDidMount(){
        this.getNom();
        AsyncStorage.setItem('anarCredits',true);
    }
    
    getNom = async () => {
        try{
          const value = await AsyncStorage.getItem('nom');
          if(value !== null){
            this.setState({nom: value});
          }
        }catch(e){
          console.log(e);
        }
      }

    changeScreen() {
      setTimeout(()=>{
        if(this.state.nom){
          console.log("entra");
      //   this.props.navigation.navigate('Prova', this.state);
       this.props.navigation.navigate('Home', this.state);
      }else{
          this.props.navigation.navigate('Login', this.state);
      }

      },100) 
    }

    render(){
        return(
            <View style={style.container}>
                <TouchableOpacity style={style.TouchableOpacity} onPress={() =>{this.changeScreen()}}>
                  <ImageBackground source="https://iternaturastrapi.ferranmaso.com/uploads/Pantalla2_7d6141cb78.png" style={style.imageBackground}>
                  </ImageBackground>
                </TouchableOpacity>
            </View>
        )
    }
}