import api from "../components/config/api_urls";

export function postStadistic(data) {
    let formBody = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return fetch(api.postEstadistiques, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formBody
    })
        .then(response => response.json())
        .then( async responseJson => {
            console.log(responseJson)
        })
        .catch(error => {
            console.error(error);
        });
}

export function postSatisfaccio(data) {
    let formBody = [];
    for (let property in data) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return fetch(api.postSatisfaccio, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formBody
    })
        .then(response => response.json())
        .then( async responseJson => {
            console.log(responseJson)
        })
        .catch(error => {
            console.error(error);
        });
}