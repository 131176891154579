import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "column"
    },

    imageBackground: {
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center"
    },

    image: {
      flex: 2,
      alignSelf: "baseline",
      marginTop: 50,
      height: 300,
      width: 300
    },

    text: {
      color: "black",
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: 100,
      marginTop: 70
    },

    titol: {
      color: "black",
      fontSize: 42,
      fontWeight: "bold",
      textAlign: "center",
      marginTop: 15,
    },

    TouchableOpacity: {
        width: 50,
        height: 50,
        marginRight: 15, 
        marginTop: -70,
        alignSelf: "flex-end"
    },

    icone: {
        width: 50,
        height: 50,
        resizeMode: "stretch",
    }
  });
