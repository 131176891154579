import React, {useCallback, useEffect} from "react";
import {
    View,
    Text,
    ImageBackground,
    Image,
    TouchableOpacity, Dimensions
} from "react-native";

import { useFocusEffect, useIsFocused } from '@react-navigation/native';

import style from "./style";
import * as Location from 'expo-location';

import FlashMessage, { showMessage, hideMessage } from "react-native-flash-message";


//=> Repo webgrafia
//=> https://mmomtchev.github.io/rlayers/#/overlays

//=> DOC NovaLlibreria
//=> https://mmomtchev.github.io/rlayers/api/ 

//=> DOC Mapes
//=> https://openicgc.github.io/ 

//=> Formes geometriques
import "ol/ol.css";
import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import {Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import {getDistance} from 'ol/sphere';

//track_points.geojson
//=> Mapa
import {RMap, RLayerTileWebGL, RLayerVector, RFeature, RStyle, RGeolocation, ROverlay} from "rlayers";
import {mapaUrl, distanceEvent, distanceVisible} from './SampleData';
import GeoJSON from "ol/format/GeoJSON";
import { withNavigationFocus } from "react-navigation";
import Menu from "../menu/menu";
import geojsonFeatures from "./data/track.json";
import api from "../config/api_urls";
import Modal from "modal-react-native-web";
import Itineraris from "../itineraris/itineraris";
import AsyncStorage from "@react-native-community/async-storage";
import {postStadistic} from "../../helpers/petitions";
import * as WebBrowser from "expo-web-browser";

//=> Posta -Tot el recorregut
//=> Poi - Nomes un punt


function useMapaControl(state) {    
    const isFocused = useIsFocused()
    
    return isFocused ? < Mapa data={state} /> : <View>ERROR REFRESH THE WEBPAGE </View>; 
  }
 



class Mapa extends React.Component {

    _isMounted = false;
    _intervalInici = null;
    _interval = null;

    constructor(props) { //=> Props per premis
        super(props)
        this.state = this.props.data.route.params
        console.log(this.state)
        this.state["location"] = []
        this.state["myPosition"] = null
        this.state["loaded"] = false
        this.state["popUpShow"] = false
        this.state["popUpShowFinal"] = false
        this.state["popUpShowInici"] = false

        this.state["poiAProp"] = {}
        this.state["comingAgain"] = false

        this.state['poisDone'] = []
        this.state['poisDoneFirst'] = []
        this.state['poisDoneLast'] = []
        this.state['poisNotDone'] = []
        this.state['poisNotDoneFirst'] = []
        this.state['poisNotDoneLast'] = []
        this.state['poisActuals'] = []

        //=> 
        this.state['refreshedProva'];

        this.finalMostrat = false
        this.finalComprovat = false

        //todo: numero itinerari dinàmic
        this.state["posta"] = {} // => {total: int ,orderPois : int, posta : [{ id : poiId , passed : boolean , points : poi.points }] } //=> si no es veu clar adaptar-ho
        geojsonFeatures.features[0].geometry.coordinates = JSON.parse(this.state.itinerariActual.coordenades_track)
        this.state['poiscarregats'] = 1

        this.puntDisponibleUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_disponible').imatge_recurs.url
        this.puntActualUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_actual').imatge_recurs.url
        this.puntPrimerUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_inici').imatge_recurs.url
        this.puntPrimerRealitzatUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_inici_realitzat').imatge_recurs.url
        this.puntRealitzatUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_realitzat').imatge_recurs.url
        this.puntFinalUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_final').imatge_recurs.url
        this.puntFinalRealitzatUrl = api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_final_realitzat').imatge_recurs.url
        let itinerariActualData = this.state.itineraris.find(itinerari => itinerari.id === this.state.itinerariActual.id)

        this.state["textSocial"] = encodeURI(this.state.projecte['textSocial' + this.state.idioma]).replaceAll('#', '%23')

        //todo: revisar si no existeix i carregar-ho
        this.state['pois'] = itinerariActualData.pois
        this.state['itinerariPremis'] = this.state.projecte.premis.filter(e => (e.itinerari === this.state.itinerariActual.id && !e.itinerari.superPremi))
        this.state['puntsItinerari'] = this.state.itinerariPremis.map(premi => premi.punts).reduce((prev, next) => prev + next)
        this.carregarEstatPois();

    }

    componentDidMount() {
        this._isMounted = true;

        FlashMessage.setColorTheme({
            success: "#25c879",
            info: "#715f4d",
            warning: "#715f4d",
            danger: "#d990fb",
        });

        let firstPoint = this.state.pois[0]

        this.getCurrentLocation().then(() => {
            let intervalPopUp = false
            this._intervalInici = setInterval(() => { //Start the timer
                /*if (!intervalPopUp && !this.state.currentStateItinerari.comencat &&
                    getDistance([parseFloat(firstPoint.lat), parseFloat(firstPoint.lon)], this.state.location) <= 1000) {
                    intervalPopUp = true
                    this.state.currentStateItinerari.comencat = true
                    localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari))
                    this.setState({popUpShowInici: !this.state.currentStateItinerari.comencat})
                }*/

                if (!intervalPopUp && !this.state.currentStateItinerari.comencat && getDistance([parseFloat(firstPoint.lat), parseFloat(firstPoint.lon)], this.state.location) <= 1000) {
                    localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari))
                    this.setState({popUpShowInici: !this.state.currentStateItinerari.comencat})
                    intervalPopUp = true
                    this.state.currentStateItinerari.comencat = true
                }
            }, 3000)

            this.setState({loaded: true})

            this._interval = setInterval(() => {
                if(this.state.currentStateItinerari.comencat) {
                    console.log('Checking completat');
                    let completat = this.checkCompletat()
                    if(!completat) {
                        console.log('Checking poi proxim')
                        this.carregarPopUp()
                    }
                }
            }, 5000);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this._intervalInici)
        clearInterval(this._interval)
    }

    getDataPois() {
        return fetch(api.geoparc_poi_all, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .then(response => response.json())
            .then(responseJson => {
                this.setState(
                    {
                        itineraris: responseJson,
                    }
                );

                /*if (this.state.urlImatgeInicial == "") this.changeScreen();*/

            })
            .catch(error => {
                console.error(error);
            });
    }

    carregarEstatPois = () => {
        // Retrieve the object from storage

        let retrievedObject = localStorage.getItem('itinerari' + this.state.itinerariActual.id);
        if (retrievedObject && JSON.parse(retrievedObject).pois.length === this.state.pois.length) this.state['currentStateItinerari'] = JSON.parse(retrievedObject);
        else {
            let biggerOrder = {id: [], order: 0}
            this.state['currentStateItinerari'] = {
                id: this.state.itinerariActual.id,
                comencat: false,
                finalitzat: false,
                currentOrder: 1,
                pois: [],
                puntsTotalsAconseguits: 0,
            }
            let unique_id = 0;
            this.state.pois.map(element => {
                if (biggerOrder.order < element.Order)
                    biggerOrder = {id: [element.id], order: element.Order}
                else if (biggerOrder.order === element.Order)
                    biggerOrder.id.push(element.id)
                this.state.currentStateItinerari.pois.push({
                    id: element.id,
                    isFirst: element.Order === 1,
                    order: element.Order,
                    isLast: false,
                    done: false,
                    popUpLoaded: false,
                })
            });
            for (let id of biggerOrder.id) {
                this.state.currentStateItinerari.pois.find(e => e.id === id).isLast = true;
            }
            localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari));
        }

        for(let poi of this.state.pois) {


            const f = new Feature(
                {
                    //lat and lon estàn girades
                    geometry: new Point(fromLonLat([parseFloat(poi.lat), parseFloat(poi.lon)])),
                    name: poi["Nom" + this.state.idioma],
                    uid: poi.id })
            let currentState = this.state.currentStateItinerari.pois.find(p => p.id === poi.id)
            if(currentState.done && currentState.isFirst) this.state.poisDoneFirst.push(f)
            else if(currentState.done && currentState.isLast) this.state.poisDoneLast.push(f)
            else if(currentState.done) this.state.poisDone.push(f)
            else if(currentState.isFirst) this.state.poisNotDoneFirst.push(f)
            else if(currentState.isLast) this.state.poisNotDoneLast.push(f)
            else if(currentState.order === this.state.currentStateItinerari.currentOrder) this.state.poisActuals.push(f)
            else this.state.poisNotDone.push(f)

        }
        /**/


    }

    checkCompletat = () => {
        console.log("final mostrat" + this.finalMostrat)
        if(!this.finalMostrat) {
            let completat = true
            for (let itinerari of this.state.currentStateItinerari.pois) {
                if (!itinerari.done) {
                    completat = false
                    return false
                }
            }
            console.log("comprovant itinerari finalitzat", completat)
            if (completat && !this.finalMostrat) {
                if(!this.state.currentStateItinerari.finalitzat) {
                    AsyncStorage.setItem('finalitzacio_itinerari', this.state.itinerariActual.Nom);

                    postStadistic({
                        token: this.state.user_token,
                        tipus_peticio: "Fi itinerari",
                        peticio: "Fi itinerari " + this.state.itinerariActual.Nom
                    })
                }
                this.finalMostrat = true
                console.warn("Itinerari completat")
                this.state.currentStateItinerari.finalitzat = true
                this.setState({popUpShowFinal: true})
                localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari))
                return true
                //this.props.navigation.navigate('PremiItinerari', this.state)
            }
            return completat
        }
        return true
    }


    getCurrentLocation = async function () {
        let location = await Location.getCurrentPositionAsync({});
        await this.setState({location: [location.coords.longitude, location.coords.latitude]});
        await this.setState({myPosition: new Point(fromLonLat([location.coords.longitude, location.coords.latitude]))})

        //this.setState({ myPosition: new Point(fromLonLat([41.637828, 1.877849]))})
    }

    getPoi(id) {
        return this.state.currentStateItinerari.pois.find(e => e.id === id)
    }

    getStatePoi(id) {
        return this.state.currentStateItinerari.pois.find(e => e.id === id)
    }

    clickPoiId(poiStatus, poi, activar_punt) {
 
        if (!poiStatus.done && activar_punt===1) {
            //this.carregarJoc(poi)

            this.carregarJoc(poi)
        } else if (this.state.poiscarregats === 2) {
            //todo: mostrar missatge punts carregant
            console.error("punts no carregats, carregant novament...")
            //this.carregarPois();
        } else if (activar_punt === 2) {
            //todo: afegir missatge alerta: t'has d'acostar més per aconseguir el punt
            showMessage({
                message: this.state.recursos.find(e => e.tipus_recurs === 'distancia_titol')['text_recurs' + this.state.idioma],
                description: this.state.recursos.find(e => e.tipus_recurs === 'distancia_descripcio')['text_recurs' + this.state.idioma],
                type: "warning",
                icon: { icon: "auto", position: "left" },
            });
            setTimeout(() => {
                hideMessage()
            }, 5000);
        } else if (activar_punt === 3) {
            //todo: afegir missatge alerta: no et toca aquest punt
        } else {
            //todo: controlar aquest apartat, no hauria d'arribar mai
            // poisCarregats = 0, s'està carregant
            // activar_punt = 1
        }
        return false
    }

    carregarJocById(id) {
            let poi = this.state.pois.find(p => p.id === id)
            this.carregarJoc(poi)
    }

    async canviarPuntActual() {
        let poiActual = this.state.currentStateItinerari.pois.find(poi => poi.order === this.state.currentStateItinerari.currentOrder + 1)

        const f = this.state.poisNotDone.find(
            (x) => x.get("uid") === poiActual.id
        );

        const idx = this.state.poisNotDone.find(
            (x) => x.get("uid") === poiActual.id
        );

        if (f && idx) {
            let poisDoneAux = this.state.poisActuals
            poisDoneAux.push(f)

            let poisNotDoneAux = this.state.poisNotDone
            poisNotDoneAux.splice(idx, 1);

            await this.setState({poisNotDone: poisNotDoneAux})
            await this.setState({poisActual: poisDoneAux})
        }
    }

    async carregarJoc(poi) {
        await this.setState({gameActive: poi})
        await this.setState({comingAgain: true})
        await this.setState({popUpShow: false})

        //IMPORTANT: Aquí realitzem les interaccions amb el punt
        this.state["poi"] = poi

        this.state["puntsProva"] = this.state.projecte.premis.find(e => e.id === poi.premi).punts

        //todo: eliminar si va bé
        await this.canviarPuntActual()

        //set comencat a true per si no s'havia fet
        this.state.currentStateItinerari.comencat = true
        localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari))

        setTimeout(async function () { //Start the timer
            switch (poi.Tipus) {
                case "Quizz":
                    // this.props.navigation.navigate
                    this.props.data.navigation.navigate('Quizz', this.state)
                    break
                case "QuizzImatges":
                    this.props.data.navigation.navigate('Quizz', this.state)
                    break
                case "QuizzLabel": //quizzlabel = quizzText
                    this.props.data.navigation.navigate('QuizzText', this.state)
                    break
                case "Checkin":
                    this.props.data.navigation.navigate('CheckIn', this.state)
                    break
                case "Info":
                    this.props.data.navigation.navigate('CheckIn', this.state)
                    break
                case "Joc360":
                    this.props.data.navigation.navigate('CheckIn', this.state)
                    break
                case "DragAndDrop":
                    this.props.data.navigation.navigate('DragAndDrop', this.state)
                    break
            }
        }.bind(this), 10)
    }

    closePopUp() {
        this.setState({popUpShow: false});
    }

    closePopUpInici() {
        this.setState({popUpShowInici: false})
        this.state.currentStateItinerari["comencat"] = true
        localStorage.setItem('itinerari' + this.state.itinerariActual.id, JSON.stringify(this.state.currentStateItinerari));

    }

    closePopUpFinal() {
        this.setState({popUpShowFinal: false});
    }

    carregarPopUp() {
        if (!this.state.popUpShow) {
            this.state.pois.map(element => {
                let itinerari = this.state.currentStateItinerari.pois.find(e => e.id === element.id);
                if (!itinerari.popUpLoaded && this.state.currentStateItinerari.currentOrder === element.Order) {
                    let poiStatus = this.getStatePoi(element.id)
                    console.log(distanceEvent)
                    let metresActivacio = (element.metresActivacio ) ? element.metresActivacio : 20
                    console.log('====================================');
                    console.log("DISTANCIA");
                    console.log(getDistance([parseFloat(element.lat), parseFloat(element.lon)], this.state.myPosition));
                    console.log(metresActivacio);
                    console.log('====================================');
                    if (getDistance([parseFloat(element.lat), parseFloat(element.lon)], this.state.myPosition) <= metresActivacio && !poiStatus.done) {
                        this.setState({popUpShow: true})
                        console.warn("suficientment a prop del punt per activar el pop up")
                        console.warn(element)
                        this.setState({poiAProp: element})
                        if(poiStatus.isFirst) {
                            const f = this.state.poisNotDoneFirst.find((x) => x.get("uid") === element.id);
                            if(!f) console.error("Poi first not found" + element.id)
                            this.setState({poiAPropDrawer: {
                                    f: f,
                                    tipus: "first"
                                }
                            })
                        }
                        else if(poiStatus.isLast) {
                            const f = this.state.poisNotDoneLast.find((x) => x.get("uid") === element.id);
                            if(!f) console.error("Poi last not found" + element.id)
                            this.setState({poiAPropDrawer: {
                                    f: f,
                                    tipus: "last"
                                }
                            })
                        }
                        else {
                            const f = this.state.poisActuals.find((x) => x.get("uid") === element.id);
                            console.log(f)
                            if(!f) console.error("Poi actual not found" + element.id)
                            if(f) {
                                this.setState({poiAPropDrawer: {
                                        f: f,
                                        tipus: "actual"
                                    }
                                })
                            }
                            else {
                                const f = this.state.poisNotDone.find((x) => x.get("uid") === element.id);
                                if(!f) console.error("Poi not done not found" + element.id)
                                this.setState({poiAPropDrawer: {
                                        f: f,
                                        tipus: "notDone"
                                    }
                                })
                            }
                        }

                        this.state.currentStateItinerari.pois.find(e => e.id === element.id).popUpLoaded = true;
                    }
                }
            })
        }
    }

    _renderLayouts() {
        let items = []
        if(this.state.itinerariActual.mapes && this.state.itinerariActual.mapes.layouts) {
            let unique_id = 1000
            let unique_id2 = 10000
            for(let layout of this.state.itinerariActual.mapes.layouts) {
                if(layout.tipus === 'mapa') {

                    items.push(
                        <RLayerTileWebGL

                            properties={{label: "IternaturaStyle"}}
                            url={layout.url}
                            key={unique_id++}
                        />
                    )
                }
                else if(layout.tipus === 'track') {
                    items.push(
                        <RLayerVector
                            features={new GeoJSON({
                                featureProjection: "EPSG:3857",
                            }).readFeatures(geojsonFeatures)}
                            key={unique_id2++}
                        >
                            <RStyle.RStyle>
                                <RStyle.RStroke color={this.state.itinerariActual.color_itinerari}
                                                width={3}/>
                                <RStyle.RFill color="transparent"/>
                            </RStyle.RStyle>
                        </RLayerVector>
                    )
                }
            }
        }
        else {
            //carraguem mapa per defecte
            console.error("error carregant mapes, carraguem mapes per defecte....")
            items.push(<RLayerTileWebGL
                properties={{label: "IternaturaStyle"}}
                url="https://geoserveis.icgc.cat/icc_mapesmultibase/noutm/wmts/orto/GRID3857/{z}/{x}/{y}.png"
            />)
        }
        return items
    }

    _renderMap() {
        return (
            <RMap
                width={this.state.fixedWidth}
                height={this.state.fixedHeight}
                initial={{center: fromLonLat(this.state.location), zoom: this.state.itinerariActual.mapes.maxZoom}}
                maxZoom={mapaUrl.maxZoom}
                // onRenderComplete = {()=>{console.log("Test Render") }}
            >
                {this._renderLayouts()}

                <RLayerVector zIndex={10}>
                    <RStyle.RStyle>
                        <RStyle.RIcon
                            src={api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'geolocalitzacio').imatge_recurs.url} //=> Canviar per icona de ubicacio que es vulgui (tambe es pot agafar per l'api)
                            scale={0.08}
                        />
                    </RStyle.RStyle>
                    <RFeature
                        geometry={new Point(fromLonLat(this.state.myPosition))}/> {/* EN CAS DE FALLAR MOLT AGAFAR DIRECTAMENT DE LA FUNCIO CRIDADA AL diMount*/}
                </RLayerVector>

                {this._renderMyPosition()}
                {this._renderPoisDone()}
                {this._renderPoisDoneFirst()}
                {this._renderPoisDoneLast()}
                {this._renderPoisNotDone()}
                {this._renderPoisActual()}
                {this._renderPoisNotDoneFirst()}
                {this._renderPoisNotDoneLast()}

            </RMap>
        )
    }

    _renderPoisDone() {

        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntRealitzatUrl}
                                  scale={poiIconSize}

                    />
                </RStyle.RStyle>
                {this.state.poisDone.map((f: Feature) => (
                    <RFeature key={f.get("uid")} feature={f} />
                ))}
            </RLayerVector>
            )

    }

    _renderPoisDoneFirst() {
        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntPrimerRealitzatUrl}
                                  scale={poiIconSize}

                    />
                </RStyle.RStyle>
                {this.state.poisDoneFirst.map((f: Feature) => (
                    <RFeature key={f.get("uid")} feature={f} />
                ))}
            </RLayerVector>
        )
    }

    _renderPoisDoneLast() {
        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntFinalRealitzatUrl}
                                  scale={poiIconSize}

                    />
                </RStyle.RStyle>
                {this.state.poisDoneLast.map((f: Feature) => (
                    <RFeature key={f.get("uid")} feature={f} />
                ))}
            </RLayerVector>
        )
    }

    clickPoi(f, tipusPunt) {
        console.log("activant punt last")
        let activarPunt = this.activarPuntStatus(f)
        console.log(activarPunt)

        if(activarPunt.activar == 1) {
            if (this.state.currentStateItinerari.currentOrder === activarPunt.poi.Order) {
                if(tipusPunt === "notDone") {
                    const idx = this.state.poisNotDone.findIndex(
                        (x) => x.get("uid") === f.get("uid")
                    );
                    if (idx >= 0) {
                        let poisDoneAux = this.state.poisDone
                        poisDoneAux.push(f)

                        let poisNotDoneAux = this.state.poisNotDone
                        poisNotDoneAux.splice(idx, 1);

                        this.setState({poisNotDone: poisNotDoneAux})
                        this.setState({poisDone: poisDoneAux})
                    }
                }
                else if(tipusPunt === "actual") {
                    if (this.state.currentStateItinerari.currentOrder === activarPunt.poi.Order) {
                        const idx = this.state.poisActuals.findIndex(
                            (x) => x.get("uid") === f.get("uid")
                        );
                        if (idx >= 0) {
                            let poisDoneAux = this.state.poisDone
                            poisDoneAux.push(f)

                            let poisActualsAux = this.state.poisActuals
                            poisActualsAux.splice(idx, 1);

                            this.setState({poisActuals: poisActualsAux})
                            this.setState({poisDone: poisDoneAux})
                        }
                    }
                }
                else if(tipusPunt === "first") {
                    if (this.state.currentStateItinerari.currentOrder === activarPunt.poi.Order) {
                        const idx = this.state.poisNotDoneFirst.findIndex(
                            (x) => x.get("uid") === f.get("uid")
                        );
                        if (idx >= 0) {
                            let poisDoneAux = this.state.poisDoneFirst
                            poisDoneAux.push(f)

                            let poisNotDoneAux = this.state.poisNotDoneFirst
                            poisNotDoneAux.splice(idx, 1);

                            this.setState({poisNotDoneFirst: poisNotDoneAux})
                            this.setState({poisDoneFirst: poisDoneAux})
                        }
                    }
                }
                else if(tipusPunt === "last") {
                    if (this.state.currentStateItinerari.currentOrder === activarPunt.poi.Order) {
                        const idx = this.state.poisNotDoneLast.findIndex(
                            (x) => x.get("uid") === f.get("uid")
                        );
                        if (idx >= 0) {
                            let poisDoneAux = this.state.poisDoneLast
                            poisDoneAux.push(f)

                            let poisNotDoneAux = this.state.poisNotDoneLast
                            poisNotDoneAux.splice(idx, 1);

                            this.setState({poisNotDoneLast: poisNotDoneAux})
                            this.setState({poisDoneLast: poisDoneAux})
                        }
                    }
                }
            }
        }
        this.clickPoiId(activarPunt.poiStatus, activarPunt.poi, activarPunt.activar)
    }

    _renderPoisNotDone() {
        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntDisponibleUrl} scale={poiIconSize} />
                </RStyle.RStyle>
                {this.state.poisNotDone.map((f: Feature) => (
                    <RFeature
                        key={f.get("uid")}
                        feature={f}
                        onClick={(e) => {
                            this.clickPoi(f, "notDone")
                        }}
                    />
                ))}
            </RLayerVector>
        )
    }

    _renderPoisActual() {
        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntActualUrl} scale={poiIconSize} />
                </RStyle.RStyle>
                {this.state.poisActuals.map((f: Feature) => (
                    <RFeature
                        key={f.get("uid")}
                        feature={f}
                        onClick={() => {
                            this.clickPoi(f, "actual")
                        }}
                    />
                ))}
            </RLayerVector>
        )
    }

    _renderPoisNotDoneFirst() {
        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntPrimerUrl} scale={poiIconSize} />
                </RStyle.RStyle>
                {this.state.poisNotDoneFirst.map((f: Feature) => (
                    <RFeature
                        key={f.get("uid")}
                        feature={f}
                        onClick={() => {
                            this.clickPoi(f, "first")
                        }}
                    />
                ))}
            </RLayerVector>
        )
    }

    _renderPoisNotDoneLast() {

        let poiIconSize = 0.1;

        return (
            <RLayerVector>
                <RStyle.RStyle>
                    <RStyle.RIcon src={this.puntFinalUrl} scale={poiIconSize} />
                </RStyle.RStyle>
                {this.state.poisNotDoneLast.map((f: Feature) => (
                    <RFeature
                        key={f.get("uid")}
                        feature={f}
                        onClick={() => {
                            this.clickPoi(f, "last")
                        }}
                    />
                ))}
            </RLayerVector>
        )
    }

    activarPuntStatus(f) {
        let activar_punt = 0;
        let idPoi = f.get("uid")
        let poi = this.state.pois.find(p => p.id === idPoi)
        let poiStatus = this.getStatePoi(idPoi)
        console.log(this.state.currentStateItinerari, poi.Order)
        if (this.state.currentStateItinerari.currentOrder === poi.Order) {
            if (poi.distancia_activacio) {
                let position = [parseFloat(poi.lat), parseFloat(poi.lon)];
                let distancia = getDistance(position, this.state.myPosition)

                console.log("DISTANCIA amb el jugador : " + distancia);
                let metresActivacio = (poi.metresActivacio ) ? poi.metresActivacio : 20

                if (distancia <= metresActivacio) activar_punt = 1;
                else activar_punt = 2; //todo canviar a 2
            } else {
                activar_punt = 1;
            }
        } else {
            //activar_punt = 3
            activar_punt = 3
        }
        return {activar: activar_punt, poiStatus: poiStatus, poi: poi}
    }

    _renderMyPosition() {
        return (
            <RGeolocation //=> d'aquesta manera s'evita el hack de pokemon go (flaying)
                tracking={true}
                // todo: activar en cas de mòbil potent, activar des de opcions? consultar iternatura
                trackingOptions={{enableHighAccuracy: true, maximumAge: 1000, timeout: 6000}}
                onChange={(e) => {
                    const geoloc = e.target;
                    this.setState({myPosition: geoloc.position_})
                }}
            />
        )
    }

    _renderPopUpInicial() {
        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}>
                <View style={style.modalOutBackground}>
                    <View style={style.caixaInicial}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                this.closePopUpInici()
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <Text style={style.popUpTitleInicial}>{this.state.itinerariActual["Nom" + this.state.idioma]}</Text>
                            <Text style={style.popUpSubtitle}>{this.state.recursos.find(e => e.tipus_recurs === 'popup_inici_itinerari_1')['text_recurs' + this.state.idioma]}</Text>
                            <Text style={style.popUpIndicatorText}>{this.state.recursos.find(e => e.tipus_recurs === 'popup_inici_itinerari_2')['text_recurs' + this.state.idioma]}</Text>
                            <Image style={{resizeMode: "contain", width: "70%", height: 100, marginTop: 15, marginBottom: 15}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'itinerari_punt_inici').imatge_recurs.url}} />
                            <TouchableOpacity style={style.popUpButton} onPress={() => {
                                console.log("go to poi")
                                this.closePopUpInici()
                            }}>
                                <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                            </TouchableOpacity>
                        </ImageBackground>
                    </View>
                </View>
            </Modal>
        )
    }

    _renderPopUp() {
        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}>
                <View style={style.modalOutBackground}>
                    <View style={style.caixa}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                this.closePopUp()
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <Text style={style.popUpTitle}>{this.state.poiAProp["Nom" + this.state.idioma]}</Text>
                            <Text
                                style={style.popUpSubtitle}>{this.state.recursos.find(e => e.tipus_recurs === 'popup_mapa_itinerari_1')['text_recurs' + this.state.idioma]}</Text>
                            <Text
                                style={style.popUpIndicatorText}>{this.state.recursos.find(e => e.tipus_recurs === 'popup_mapa_itinerari_2')['text_recurs' + this.state.idioma]}</Text>
                            <TouchableOpacity style={style.popUpButton} onPress={() => {
                                if(this.state.poiAProp) this.clickPoi(this.state.poiAPropDrawer.f, this.state.poiAPropDrawer.tipus)
                            }}>
                                <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                            </TouchableOpacity>
                        </ImageBackground>
                    </View>
                </View>
            </Modal>
        )
    }

    _handlePressButtonAsync = async (link) => {
        let result = await WebBrowser.openBrowserAsync(link);
    };

    _renderPopUpFinal() {
        let mineral = this.state.premisAlternatius.find(e => e.itinerari === this.state.itinerariActual.id)

        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}>
                <View style={style.modalOutBackground}>
                    <View style={style.caixaFinal}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2_confeti')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                this.closePopUpFinal()
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <View style={{marginTop: 20}}></View>
                            <Text style={style.popUpSubtitleConfeti}>{this.state.recursos.find(e => e.tipus_recurs === 'text_finalitzacio_itinerari')['text_recurs' + this.state.idioma]}</Text>
                            <Image style={{resizeMode: "contain", width: "70%", height: 150}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'talpo_final_itinerari')['imatge_recurs'].url}} />

                            <TouchableOpacity style={style.popUpButton} onPress={async () => {

                                await this.setState({currentMineralLink: mineral.Link })
                                await this.setState({popUpShowFinal: false})
                                await this.setState({carregarItinerariPerfil: this.state.itinerariActual.id})
                                this.props.navigation.navigate('Mineral', this.state)
                            }}>
                                <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_veure_recompensa')['text_recurs' + this.state.idioma]}</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={style.popUpButton} onPress={async () => {
                                await this.setState({popUpShowFinal: false})
                                await this.setState({carregarItinerariPerfil: this.state.itinerariActual.id})
                                this.props.navigation.navigate('Perfil', this.state)
                            }}>
                                <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_mostrar_inventari')['text_recurs' + this.state.idioma]}</Text>
                            </TouchableOpacity>

                            <View style={{width: "100%", marginTop: 13, display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'center', visibility: "hidden"}}>
                                <View style={{justifyContent: "center", alignItems: "center", marginLeft: 3, marginRight: 3}}>
                                    <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={async () => {
                                        this._handlePressButtonAsync("https://www.facebook.com/sharer/sharer.php?u=https://joc.geoparc.cat&t=" +
                                            this.state.textSocial +
                                            " https%3A%2F%2Fjoc.geoparc.cat")
                                        this.postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes facebook (" + this.state.itinerariActual.Nom + ")"})
                                    }}>
                                        <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_facebook')['imatge_recurs'].url}}/>
                                    </TouchableOpacity>
                                </View>
                                <View style={{justifyContent: "center", alignItems: "center", marginLeft: 3, marginRight: 3}}>
                                    <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={async () => {
                                        this._handlePressButtonAsync("https://twitter.com/intent/tweet?text=" +
                                            this.state.textSocial)
                                        this.postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes twitter (" + this.state.itinerariActual.Nom + ")"})
                                    }}>
                                        <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_twitter')['imatge_recurs'].url}}/>
                                    </TouchableOpacity>
                                </View>
                                <View style={{justifyContent: "center", alignItems: "center", marginLeft: 3, marginRight: 3}}>
                                    <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.11}} onPress={async () => {
                                        this._handlePressButtonAsync("whatsapp://send?text=" +
                                            this.state.textSocial +
                                            " https%3A%2F%2Fjoc.geoparc.cat")
                                        this.postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes whatsapp (" + this.state.itinerariActual.Nom + ")"})
                                    }}>
                                        <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_whatsapp')['imatge_recurs'].url}}/>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={{width: "100%", position: "absolute", bottom: 5, marginTop: 13, display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: 'center'}}>
                                <View style={{justifyContent: "center", alignItems: "center", marginLeft: 3, marginRight: 3}}>
                                    <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.50) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.50) * 0.11}} onPress={async () => {
                                        this._handlePressButtonAsync("https://www.facebook.com/sharer/sharer.php?u=https://joc.geoparc.cat&t=" +
                                            this.state.textSocial +
                                            " https%3A%2F%2Fjoc.geoparc.cat")
                                        this.postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes facebook (" + this.state.itinerariActual.Nom + ")"})
                                    }}>
                                        <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_facebook')['imatge_recurs'].url}}/>
                                    </TouchableOpacity>
                                </View>
                                <View style={{justifyContent: "center", alignItems: "center", marginLeft: 3, marginRight: 3}}>
                                    <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.50) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.50) * 0.11}} onPress={async () => {
                                        this._handlePressButtonAsync("https://twitter.com/intent/tweet?text=" +
                                            this.state.textSocial +
                                            " https%3A%2F%2Fjoc.geoparc.cat")
                                        this.postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes twitter (" + this.state.itinerariActual.Nom + ")"})
                                    }}>
                                        <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_twitter')['imatge_recurs'].url}}/>
                                    </TouchableOpacity>
                                </View>
                                <View style={{justifyContent: "center", alignItems: "center", marginLeft: 3, marginRight: 3}}>
                                    <TouchableOpacity style={{height: (Dimensions.get('screen').height * (13 / 15) * 0.50) * 0.11, width: (Dimensions.get('screen').height * (13 / 15) * 0.50) * 0.11}} onPress={async () => {
                                        this._handlePressButtonAsync("whatsapp://send?text=" +
                                            this.state.textSocial +
                                            " https%3A%2F%2Fjoc.geoparc.cat")
                                        this.postStadistic({token: this.state.user_token, tipus_peticio: "Compartir xarxes", peticio: "Compartir xarxes whatsapp (" + this.state.itinerariActual.Nom + ")"})
                                    }}>
                                        <Image style={{height: "100%", width: "100%"}} source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'perfil_whatsapp')['imatge_recurs'].url}}/>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </ImageBackground>
                    </View>
                </View>
            </Modal>
        )
    }

    _renderIntro() {
        return (
            <View>
                <Text>Carregant mapes...</Text>
            </View>
        )
    }

    render() {
        if(Dimensions.get('window').width != this.state.fixedWidth || Dimensions.get('window').height != this.state.fixedHeight)
        {
            this.setState({refreshedProva : false})
            this.setState({fixedWidth: Dimensions.get('window').width});
            this.setState({fixedHeight:Dimensions.get('window').height});
        }
        return (
        <View style={{flex: 1}}>
                {!this.state["loaded"] ?
                    this._renderIntro() :
                    (
                        <View>
                            {this.state['refreshedProva'] ? this._renderMap() : (this.setState({refreshedProva : true}))}
                            <View style={{flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed'}}>
                                <Menu projecte={this.state}/>
                            </View>

                            {/* MODAL */}
                            {this.state.popUpShow ? this._renderPopUp() : null}
                            {this.state.popUpShowInici ? this._renderPopUpInicial() : null}
                            {this.state.popUpShowFinal ? this._renderPopUpFinal() : null}
                        </View>
                    )}
            <FlashMessage position="top" />

        </View>
        );
    }
}


export default useMapaControl;
//export default withNavigationFocus(Mapa);

/*
//=> Per fer animacions

 e.map.getView().fit(e.target.getGeometry().getExtent(), {
              duration: 250,
              maxZoom: 15,
            })

*/