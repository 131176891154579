import React from 'react';
import {SafeAreaView, FlatList, View, Text, TextInput, ImageBackground, TouchableOpacity, Image, Dimensions} from 'react-native';
import style from './style';
import api from '../../config/api_urls'
import {gameId} from '../../Mapa/SampleData';
import Menu from "../../menu/menu";
import CardFlip from 'react-native-card-flip';
import Modal from "modal-react-native-web";

export default class QuizzText extends React.Component {

    constructor(props) {
        super(props);
        this.state = props.route.params
        this.state["showQuizz"] = false
        this.state["showContinue"] = false
        this.state["load"] = false
        this.state["popUpShowQuizz"] = false
        this.state["pista"] = 0
        this.state["contestat"] = 0
        //retard d'un segon per evitar bug de react que carrega la funció que se li passa per render
        //todo: trobar solució a aquest problema, potser actualitzant versió...
        setTimeout(function() { //Start the timer
            this.setState({ load: true })
            console.log("load = true")
        }.bind(this), 1000)
    }

    _goQuizz() {
        console.log("trying to go quizz")
        if(this.state.load) {
            console.log("go quizz")
            this.setState({showQuizz: true});
        }
    }

    closePopUp() {
        this.setState({resposta: ""})
        this.setState({popUpShowQuizz: false})
    }

    GoPremi() {
        if(this.state.load) {
            this.props.navigation.navigate('Premi', this.state);
        }
    }

    async ActivarPista() {
        //recullir pistes
        let puntsActuals = (this.state.puntsProva - 1 <= 0) ? 0 : this.state.puntsProva - 1
        this.setState({puntsProva: puntsActuals})
        if(this.state.pista === "" || this.state.pista + 1 > this.state.poi["Quizz" + this.state.idioma].pistes.length)  {
            await this.setState({pista: ""})
        }
        else {
            await this.setState({pista: this.state.pista + 1})
        }
        if(this.state.pista === "") {
            this.pistaActual = this.state.recursos.find(e => e.tipus_recurs === 'text_resposta_correcta')['text_recurs' + this.state.idioma]
                                + ": " + this.state.poi["Quizz" + this.state.idioma].respostes[0].valid
        }
        else {
            this.pistaActual = this.state.poi["Quizz" + this.state.idioma].pistes.find(pista => pista.id === this.state.pista)
            this.pistaActual = (this.pistaActual && this.pistaActual.valid) ? this.pistaActual.valid : ""
        }
        this.setState({popUpShowQuizz: true})
    }

    norm = (text) => {
        if(text && text !== "") {
            return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z]/g, "").toLowerCase()
        }
        else {
            return ""
        }
    }

    Respondre = () => {
        console.log(this.norm(this.state.resposta))

        if(this.state.poi["Quizz" + this.state.idioma].respostes.find(resposta =>
            this.norm(resposta.valid) === this.norm(this.state.resposta))) {
            //todo: espectacle quan s'endevini
            console.log("endevinat")
            this.setState({contestat: 1})
            this.setState({showContinue: true})
        } else {
            console.log("no endevinat")
            this.ActivarPista()
            this.setState({contestat: 2})
            this.setState({showContinue: false})
            //todo: espectacle quan falli
        }
    }

    _renderPopUp() {

        return (
            <Modal animationType="slide"
                   ariaHideApp={false}
                   transparent={true}>
                <View style={style.modalOutBackground}>
                    <View style={style.caixa}>
                        <ImageBackground
                            source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2')['imatge_recurs'].url}}
                            style={style.imageBackground}>
                            <TouchableOpacity style={style.crossBox} onPress={() => {
                                this.closePopUp()
                            }}>
                                <Image style={style.crossImage}
                                       source={{uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === 'pop_up_close_icon')['imatge_recurs'].url}}/>
                            </TouchableOpacity>
                            <Text style={style.popUpTitle}>
                                {this.state.pista !== "" ? this.state.recursos.find(e => e.tipus_recurs === 'text_pista')['text_recurs' + this.state.idioma] : "" }
                                {" "}
                                {this.state.pista}</Text>
                            <Text style={style.popUpIndicatorText}>{this.pistaActual}</Text>
                            <TouchableOpacity style={style.popUpButton} onPress={() => {
                                this.closePopUp()
                            }}>
                                <Text style={style.popUpButtonText}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                            </TouchableOpacity>
                        </ImageBackground>
                    </View>
                </View>
            </Modal>
        )
    }

    render(){
        return(
            <View style={{flex: 1}}>
                <View style={{flex: 15}}>
                    <View style={{flex: 15}}>
                        <ImageBackground source={{ uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs === '05_background_tipus2').imatge_recurs.url }} style={style.imageBackground}>
                            <View style={{flex: 33, justifyContent: 'center', alignItems: 'center', width: "100%"}}>
                                <View resizeMode="contain" style={style.questionBackground}>
                                    <Text style={style.question}>{this.state.poi["Quizz" + this.state.idioma].pregunta}</Text>
                                </View>
                            </View>
                            <View style={{flex: 28, marginBottom: 20, justifyContent: 'start', alignItems: 'center'}}>
                                <TextInput
                                    multiline={true}
                                    style={style.answerInput}
                                    numberOfLines={4}
                                    onChangeText={(text) => this.setState({resposta: text})}
                                    value={this.state.resposta}/>
                            </View>
                            <View style={{flex: 29, justifyContent: 'start', alignItems: 'center'}}>
                                {this.state.contestat != 0 ?
                                    <Image
                                        resizeMode="contain"
                                        style={style.talpSolucio}
                                        source={this.state.contestat === 1 ? {uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'resposta_encert')['imatge_recurs'].url} : {uri: api.api_url + this.state.recursos.find(e => e.tipus_recurs == 'resposta_error')['imatge_recurs'].url}}
                                    />
                                    : null
                                }
                            { this.state.showContinue ?
                                <TouchableOpacity style={style.containerContinue} onPress={() =>  this.GoPremi() }>
                                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_continuar')['text_recurs' + this.state.idioma]}</Text>
                                </TouchableOpacity>
                                :
                                <TouchableOpacity style={style.containerContinue} onPress={() => this.Respondre() }>
                                    <Text style={style.continue}>{this.state.recursos.find(e => e.tipus_recurs === 'text_respondre')['text_recurs' + this.state.idioma]}</Text>
                                </TouchableOpacity>
                            }
                            </View>
                            <View style={{flex: 10}} />

                        </ImageBackground>)
                    }
                    </View>
                </View>
                <View style={{ flex: 2, bottom: 0, left: 0, right: 0, position: 'fixed', }}>
                    <Menu projecte={this.state}></Menu>
                </View>
                {this.state.popUpShowQuizz ? this._renderPopUp() : null}
            </View>
        )
    }

    /*render() {
        return (
            <View style={{flex: 1}}>
                <ImageBackground
                    source={{uri: api.api_url + this.state.projecte['FonsPantalla4' + this.state.idioma].url}}
                    style={style.imageBackground}>
                    <View style={{flex: 15}}>

                    </View>
                </ImageBackground>
            </View>
        )
    }*/
}
