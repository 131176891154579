import { StyleSheet } from 'react-native';
import {
  Dimensions
} from "react-native";

const colorCorporatiu = 'black';


const style = StyleSheet.create({
    fontFamily: 'Open Sans',
    textLogin:{
        fontSize: 15,
        color : colorCorporatiu,
        textAlign:'center',
        fontWeight : 'bold',
        paddingTop: 20, 
    },
    container: {
        flex: 1,
        flexDirection: "column"
      },
      imageBackground: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        paddingBottom:15,
      },
      caixaOut:{
       // backgroundColor:'rgba(116, 81, 110, 0.8)',
        marginHorizontal:'10%', 
        borderRadius:10,
        flex: 1
      },
      SobreClick: {
        width: 300,
        height: 300,
        alignSelf: 'center',
        resizeMode: 'contain',
      },
      textImatge:{
        fontSize:16 ,
        alignSelf:'center',
        fontWeight:'bold',
       // color:'white',
        paddingTop:10 },
        imatgeClick: {
          height: (Dimensions.get('screen').height * (13 / 15) * 0.67) * 0.5,
          width: (Dimensions.get('screen').height * (13 / 15) * 0.67)  * 0.45,
          alignSelf: 'center',
          resizeMode: 'contain',
        },
        
      caixa:{
        flex: 1,
        marginHorizontal:10,
        marginVertical:10,
      },
      caixaBorderContenidor:{
        borderWidth: 3,
        borderRadius:10,
      },
       titol: {
      paddingTop:20,
      fontFamily: 'Lobster',
      fontSize: 30,
      textAlign: "center",
      marginBottom: 1,
      paddingBottom:8,
      width:'90%',
      alignSelf:'center'
    },
    init : {
      position: "absolute",
      marginRight: 0,
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      zIndex : 1000,
  },
});
    
export default style; 