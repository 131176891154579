import React from 'react';
import { View, Dimensions,} from 'react-native';
import style from './style';
import { WebView } from 'react-native-webview'; 
import api from '../config/api_urls';


export default class Joc3Completa extends React.Component{

    constructor(props) {
        super(props);
        this.state = props.route.params;
        console.log(this.state);
    }

    render(){
        return(
    
        <View style={style.container}>
             <iframe src={this.state.URL} style={{height: Dimensions.get('screen').height , width:'100%'}} />
        </View>
           
        )
    }
    }